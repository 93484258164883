<template>
  <div>
    <div class="columns m--0 align-self-center title-doc d-flex">
      <div class="column is-2-mobile is-2-tablet  p__left--0 is-fullwidth ">
        <p class="title-secondary color__blue_button_add fs-20 fw__bold">{{ $t('documents.detail.title') }}</p>
      </div>
      <div class="column d-flex is-10-mobile is-10-tablet is-justify-content-flex-end align-items-center title-flex ">
          <div class="w-min-content  btn-edit is-borderless"
               v-if="(userInfo && userInfo.role_id !== 4) || (documentDetail.document_type && documentDetail.document_type.id !== 5)">
            <button class="button btn-detail is-borderless color__white background__blue_button_add" @click="updateDocument()">
              <img width="18px" src="@/assets/svgs/DocDetail/ic_edit_2.svg">
              {{ $t('documents.detail.button.edit') }}
            </button>
          </div>
          <div class="p__top--0 p__bottom--0 p__left--10 btn-setting-alert"
               v-if="userInfo && userInfo.role_id !== 4 && userInfo && userInfo.role_id !== 3">
            <button class="button btn-detail  is-borderless color__white background__blue_button_add"
                    @click="settingAlertMail()">
              <img width="18px" src="@/assets/svgs/DocDetail/ic_setting notification.svg">
              {{ $t('documents.detail.button.setting_alert') }}
            </button>
          </div>
          <div class="p__top--0 p__bottom--0 p__left--10 btn-setting-role"
               v-if="(userInfo && userInfo.role_id !== 4 && userInfo && userInfo.role_id !== 3) && documentDetail.document_type && documentDetail.document_type.type === 1">
            <button class="button btn-detail is-borderless color__white background__blue_button_add "
                    @click="settingRole()">
              <img width="18px" src="@/assets/svgs/DocDetail/ic_user_setting.svg">
              {{ $t('documents.detail.button.role') }}
            </button>
          </div>
          <div class="p__top--0 p__bottom--0 p__left--10 btn--delete"
               v-if="(userInfo && userInfo.role_id !== 4) || (documentDetail.owner && documentDetail.owner.id === userInfo.id && documentDetail.document_type && documentDetail.document_type.id !== 5)">
            <button class="button btn-delete-doc color__white is-borderless"
                    @click="deleteDocument()">
              <img width="18px" src="@/assets/svgs/DocDetail/ic_delete2.svg">
              {{ $t('documents.detail.button.delete') }}
            </button>
          </div>
      </div>
    </div>
    <div class="page-main-content page-list-data">
      <div class="columns d-flex">
        <div class="column is-half p__right--25">
          <div class="align-items-center m__bottom--16">
            <div class="m__bottom--6">
              <label class=" input-title fs-14 color__blue_text_detail fw__bold">{{ $t('documents.input_field.documents.name') }}</label>
            </div>
            <div class="">
              <label>
                <input class="input fw__bold color__blue_button input-detail"
                       :value="documentDetail.name"
                       type="text"
                       readonly
                >
              </label>
            </div>
          </div>
          <div class="align-items-center m__bottom--16">
            <div class="m__bottom--6">
              <label class="input-title fs-14 color__blue_text_detail fw__bold">{{ $t('search_documents.list.office_name') }}</label>
            </div>
            <div class="">
              <label>
                <input class="input fs-14 fw__bold color__blue_button input-detail"
                       v-model.trim="organization"
                       type="text"
                       readonly
                >
              </label>
            </div>
          </div>

          <div class="align-items-center m__bottom--16">
            <div class="m__bottom--6">
              <label class="input-title color__blue_text_detail fs-14 fw__bold">{{ $t('documents.input_field.owner') }}</label>
            </div>
            <div class="">
              <label>
                <input class="input fs-14 fw__bold color__blue_button input-detail"
                       :value="documentDetail.owner ? documentDetail.owner.name : ''"
                       type="text"
                       readonly
                >
              </label>
            </div>
          </div>

          <div class="align-items-center m__bottom--16">
            <div class="m__bottom--6">
              <label class="input-title current-folder fs-14 color__blue_text_detail fw__bold">{{
                  $t('documents.input_field.current_folder')
                }}</label>
            </div>
            <div class="">
              <label>
                <input class="input fs-14 fw__bold color__blue_button input-detail"
                       :value="documentDetail.folder && documentDetail.folder.name"
                       type="text"
                       readonly
                >
              </label>
            </div>
          </div>
          <div class="align-items-center m__bottom--16" v-if="documentDetail.document_type && (documentDetail.document_type.id === 3)">
            <div class="m__bottom--6">
              <label class="input-title fs-14 color__blue_text_detail fw__bold">{{ $t('documents.contract_partner.label') }}</label>
            </div>
            <div class="">
              <label>
                <input class="input fs-14 fw__bold color__blue_button input-detail"
                       :value="documentDetail.partner_name"
                       type="text"
                       readonly
                >
              </label>
            </div>
          </div>
        </div>
        <div class="column is-half p__left--25">
          <div class="align-items-center m__bottom--16" style="opacity: 0">
            <div class="m__bottom--6">
              <label class="input-title fs-14 color__blue_text_detail fw__bold">{{ $t('documents.input_field.create_at') }}</label>
            </div>
            <div class="">
              <label>
                <input class="input fs-14 fw__bold color__blue_button input-detail"
                       :value="momentFormat(documentDetail.created_at)"
                       type="text"
                       readonly
                >
              </label>
            </div>
          </div>
          <div class="align-items-center m__bottom--16" style="opacity: 0">
            <div class="m__bottom--6">
              <label class="input-title fs-14 color__blue_text_detail fw__bold">{{ $t('documents.input_field.create_at') }}</label>
            </div>
            <div class="">
              <label>
                <input class="input fs-14 fw__bold color__blue_button input-detail"
                       :value="momentFormat(documentDetail.created_at)"
                       type="text"
                       readonly
                >
              </label>
            </div>
          </div>
          <div class="align-items-center m__bottom--16">
            <div class="m__bottom--6">
              <label class="input-title fs-14 color__blue_text_detail fw__bold">{{ $t('documents.input_field.create_at') }}</label>
            </div>
            <div class="">
              <label>
                <input class="input fs-14 fw__bold color__blue_button input-detail"
                       :value="momentFormat(documentDetail.created_at)"
                       type="text"
                       readonly
                >
              </label>
            </div>
          </div>

          <div class="align-items-center m__bottom--16">
            <div class="m__bottom--6">
              <label class="input-title fs-14 color__blue_text_detail fw__bold">{{ $t('documents.input_field.updated_at') }}</label>
            </div>
            <div class="">
              <label>
                <input class="input fs-14 fw__bold color__blue_button input-detail"
                       :value="momentFormat(documentDetail.updated_at)"
                       type="text"
                       readonly
                >
              </label>
            </div>
          </div>
          <div class="align-items-center m__bottom--16" v-if="documentDetail.document_object">
            <div class="m__bottom--6">
              <label class="input-title fs-14 color__blue_text_detail fw__bold">{{ $t('documents.doc_object.label') }}</label>
            </div>
            <div class="">
              <label>
                <input class="input fs-14 fw__bold color__blue_button input-detail"
                       :value="documentDetail.document_object && documentDetail.document_object.name"
                       type="text"
                       readonly
                >
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div class="columns">
        <span class="fs-18 color__blue_main fw__bold">{{$t('documents.detail.attribute')}}</span>
      </div>
      <div class="columns d-flex">
        <div class="column p__right--25 p__bottom--0">
          <template v-if="displayAttributes">
            <div v-for="(e, i) in attributesClone" :key="'attributes-' + i">
              <template v-if="i%2 === 0">
                <div class="m__bottom--16">
                  <div class="m__bottom--6">
                    <label class="input-title fs-14 color__blue_text_detail fw__bold">{{ e.name }}</label>
                  </div>
                  <div>
                    <input class="input fs-14 fw__bold color__blue_button input-detail"
                           :value="e.display"
                           type="text"
                           readonly
                    >
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
        <div class="column p__left--25 p__bottom--0">
          <template v-if="displayAttributes">
            <div v-for="(e, i) in attributesClone" :key="'attributes-' + i">
              <template v-if="i%2 !== 0">
                <div class="m__bottom--16">
                  <div class="m__bottom--6">
                    <label class="input-title fs-14 color__blue_text_detail fw__bold">{{ e.name }}</label>
                  </div>
                  <div>
                    <input class="input fs-14 fw__bold color__blue_button input-detail"
                           :value="e.display"
                           type="text"
                           readonly
                    >
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
      <div class="columns d-flex">
        <div class="column is-half p__right--25">
          <div v-if="documentDetail.tags && documentDetail.tags.length">
            <div>
              <label class="input-title fs-14 color__blue_text_detail fw__bold">{{ $t('documents.tag.label') }}</label>
            </div>
            <div class="tag-box">
                <span class="tag document-tags m__right--10 m__bottom--10 m__top--5 fs-12" v-for="(e, i) in documentDetail.tags" :key="'document-tags-' + i">{{ e.name }}</span>
            </div>
          </div>
        </div>
        <div class="column">
        </div>

      </div>
      <hr/>
      <div>
        <span class="fs-18 color__blue_main fw__bold">{{$t('documents.detail.list')}}</span>
      </div>
      <div class="columns d-flex">
        <div
            class="column p__bottom--0 p__top--0 align-self-center is-half-mobile is-half-desktop is-half-fullhd is-half-tablet is-half-widescreen is-half-touch">
          <span class="vertical-baseline-middle fs-12 color__gray_text_1">全{{ paginate.total || 0 }}件</span>
        </div>
        <div
            class="column p__bottom--0 p__top--0 has-text-left align-items-center d-flex is-justify-content-flex-end is-half-mobile is-half-desktop is-half-fullhd is-half-tablet is-half-widescreen is-half-touch">
          <span class="m__right--5 vertical-baseline-middle fs-12 color__gray_text_1">{{ $t('page.page-range') }}</span>
          <div class="dropdown is-right"
               id="dropdown-pagination"
               v-click-out="closeDropPageRange">
            <div class="dropdown-trigger">
              <button class="button btn-change-page-range p__top--0 p__bottom--0 p__left--10"
                      aria-haspopup="true"
                      @click="toggleDropPageRange()">
                <span class="has-text-centered w--80 p__right--5 fs-12 color__gray_text_1">{{ paginate.perPage }} 件</span>
                <span class="icon is-small">
              <img width="9px" src="@/assets/svgs/icon_drop.svg">
              </span>
              </button>
            </div>
            <div class="dropdown-menu" role="menu" style="right: 0px !important;">
              <div class="dropdown-content">
                <a @click="changePageRange(e)"
                   v-for="(e, i) in pageRanges"
                   :key="'page-range-' + i"
                   class="has-text-right dropdown-item p__right--10 fs-12 color__gray_text_1">
                  {{ e }} 件
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column p__top--0 p__left--0 p__right--0">
          <div class="table-container data-table">
            <table class="table is-striped is-hoverable is-fullwidth">
              <thead>
              <tr>
                <th class="vertical-middle input-title has-text-nowrap">{{ $t('documents.input_field.file_name') }}
                </th>
                <th class="vertical-middle input-title has-text-nowrap">{{ $t('documents.input_field.type') }}</th>
                <th class="vertical-middle input-title has-text-nowrap">{{ $t('documents.input_field.version') }}</th>
                <th class="vertical-middle input-title has-text-nowrap">{{ $t('documents.input_field.create_at') }}
                </th>
                <th class="vertical-middle input-title has-text-nowrap"
                    style="max-width: 150px !important; width: 150px">
                  {{ $t('documents.input_field.action') }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(e, i) in documentDetail.files.data"
                  :key="'user' + i"
                  :class="{'is-checked': e.checked}">
                <td class="has-word-break d-flex align-items-center">
                  <img width="25px" src="@/assets/svgs/Files/ic_doc.svg" class=" m__right--10"
                       v-if="e.file_format * 1 === 1">
                  <img width="25px" src="@/assets/svgs/Files/ic_PDF.svg" class=" m__right--10"
                       v-if="e.file_format * 1 === 2">
                  <img width="25px" src="@/assets/svgs/Files/ic_image.svg" class=" m__right--10"
                       v-if="e.file_format * 1 === 3">
                  <img width="25px" src="@/assets/svgs/Files/ic_excel.svg" class=" m__right--10"
                       v-if="e.file_format * 1 === 4">
                  <img width="25px" src="@/assets/svgs/Files/ic_PPTX.svg" class=" m__right--10"
                       v-if="e.file_format * 1 === 5">
                  <img width="25px" src="@/assets/svgs/Files/ic_txt.svg" class=" m__right--10"
                       v-if="e.file_format * 1 === 6">
                  <img width="25px" src="@/assets/svgs/Files/ic_Zip_1.svg" class=" m__right--10"
                       v-if="e.file_format * 1 === 7">
                  <span>{{ e.original_name }}</span>
                </td>
                <td class="has-no-word-break ">{{ documentDetail.document_type.name }}</td>
                <td class="">{{ e.version }}</td>
                <td class="">{{ momentFormat(e.created_at) }}</td>
                <td class="has-text-centered ">
                  <div class="d-flex is-justify-content-start">
                    <button class="blob m__right--10 has-tooltip-top button-action-detail"
                            @click="viewFile(e)"
                            :data-tooltip="displayTooltip && $t('documents.icon.preview')">
                      <img width="25px" src="@/assets/svgs/DocDetail/ic_priview.svg">
                    </button>
                    <button class="blob m__right--10 has-tooltip-top button-action-detail"
                            @click="downloadFile(e)"
                            :data-tooltip="$t('documents.icon.download')">
                      <img width="25px" src="@/assets/svgs/DocDetail/ic_download.svg">
                    </button>
                    <button class="blob has-tooltip-top button-action-detail"
                            :data-tooltip="displayTooltip && $t('documents.icon.delete')"
                            @click="deleteFile(e.id, e.original_name)"
                            v-if="(userInfo && userInfo.role_id !== 4) || (documentDetail.owner && documentDetail.owner.id === userInfo.id && documentDetail.document_type && documentDetail.document_type.id !== 5)">
                      <img width="25px" src="@/assets/svgs/DocDetail/ic_delete.svg">
                    </button>
                  </div>
                </td>
              </tr>
              <tr v-if="!documentDetail.files.data || !documentDetail.files.data.length">
                <td colspan="6" class="has-text-centered">{{ $t('target_not_found') }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-full has-text-centered m__top--10">
          <pagination
              :current="paginate.currentPage"
              :per-page="paginate.perPage"
              :total="paginate.total"
              @change="changePage($event)">
          </pagination>
        </div>
      </div>
      <div class="modal" id="modal-preview-file">
        <div class="modal-background"></div>
        <div class="modal-card" v-click-out="() => { closeModal('modal-preview-file'); resetData()}">
          <template v-if="isPreview && !isChangeRole">
            <div class="modal-card-head background__blue_modal">
              <p class="modal-card-title
                    input-title color__blue_button_add text-center fw__bold fs-20">{{ $t('documents.modal.preview.title') }}</p>
              <button class="delete" aria-label="close" @click="closeModal('modal-preview-file')"></button>
            </div>
            <section class="modal-card-body p-0">
              <div class="columns modal-field-content d-flex">
                <div class="column is-6 p__left--10 align-self-center p__bottom--0">
                  <label class="has-word-break fs-14 color__blue_text_detail"><span
                      class="input-title color__blue_text_detail fs-14">{{ $t('documents.modal.preview.name') }}:</span>
                    {{
                      fileView.original_name
                    }}</label>
                </div>
                <div class="column is-6 p__bottom--0 align-self-center is-justify-content-flex-end d-flex">
                  <div class="dropdown d-flex is-justify-content-flex-end"
                       id="dropdown-file-versions"
                       v-click-out="()=>{closeDropdown('dropdown-office'); closeDropdown('dropdown-file-versions');}">
                    <div class="d-flex is-justify-content-flex-end">
                      <button class="color__white m__right--10 is-borderless has-tooltip-bottom p__left--0 p__right--0 btn-download-file"
                              @click="downloadFileHistories(fileView.id, fileView.original_name)"
                              :data-tooltip="$t('documents.tooltip.download')">
                        <img width="25px" style="margin-top: 3px" src="@/assets/svgs/DocDetail/ic_download.svg"/>
                      </button>
                      <div class="dropdown-trigger m__right--10 d-flex align-items-center btn-download-ver">
                        <button class="button btn-download"
                                aria-haspopup="true"
                                @click="toggleDropdown('dropdown-file-versions')">
                          <span class="fs-12 color__white fw__bold">{{ fileVersion.version ? 'Version ' + fileVersion.version : '' }}</span>
                          <span class="icon is-small">
                          <img width="12px" src="@/assets/svgs/icon_drop_2.svg"/>
                        </span>
                        </button>
                      </div>
                      <div class="dropdown-menu" role="menu">
                        <div class="dropdown-content content-dropdown-version">
                          <a @click="selectFileVersion(e)"
                             v-for="(e, i) in fileVersions"
                             :key="'file-version-' + i"
                             :class="{'is-active': e.version === fileVersion.version}"
                             class="dropdown-item fs-14 color__gray_text_1">
                             {{$t('version')}}{{ e.version }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns column-preview align-items-center m-3 preview-file"
                   style="margin: 30px !important; margin-top: 0px !important;">
                <p class="has-text-centered is-size-5 w--100 align-self-center" v-if="!filePreview.url">
                  {{ $t('documents.preview.can_not_preview_file') }}
                </p>
                <div v-else style="margin: 0 auto; position: absolute" class="w--100 h--100 has-text-centered">
                  <template v-if="displayFile">
                    <img :src="filePreview.url" v-if="filePreview.type === 3" alt="">
                    <template v-else-if="filePreview.type === 2" class="preview-content">
                      <template v-if="numPages">
                        <pdf
                                v-for="i in numPages"
                                :key="i"
                                :src="src"
                                :page="i"
                        ></pdf>
                      </template>
                    </template>
                    <!--                  <iframe :src="filePreview.url" v-else-if="filePreview.type === 2" class="preview-content"></iframe>-->
                    <p class="has-text-centered is-size-5 w--100 align-self-center" v-else>
                      {{ $t('documents.preview.can_not_preview_file') }}
                    </p>
                  </template>
                </div>
              </div>
            </section>
          </template>
          <template v-if="!isPreview && isChangeRole">
            <div class="modal-card-head background__gray_dark">
              <p class="modal-card-title input-title color__blue_main text-center fs-20 fw__bold">{{
                  $t('documents.modal.setting_role.title')
                }}</p>
              <button class="delete" aria-label="close" @click="closeModal('modal-preview-file')"></button>
            </div>
            <section class="modal-card-body change-role-office "
                     @keyup.enter="submitSettingRole()">
              <div class="modal-field-content">
                <div class="m__bottom--18">
                  <div class="m__bottom--6">
                    <label class="input-title fs-14 color__blue_button fw__bold">{{$t('profile.input_field.office_name.label')}}</label>
                  </div>
                  <div class="">
                    <multiselect_permission :list-data="offices"
                                 ref="officesMultiselect"
                                 :selected="selectedOffices.map(e => e.id)"
                                 :placeholder="officePlaceholder"
                                 @change="getSelectedOffices($event)"></multiselect_permission>
                  </div>
                </div>
                <div class="m__bottom--18 " v-for="(e, i) in selectedOffices" :key="'selected-office-' + i">
                  <div class="m__bottom--6">
                    <label class="fs-14 color__blue_button fw__bold">{{ e.name }}</label>
                  </div>
                  <div class="d-flex is-align-items-flex-start is-justify-content-space-between">
                    <div style="min-width: 90%">
                      <multiselect_permission :list-data="positionStaffs"
                                   :ref="`multiSelect${i}`"
                                   :placeholder="positionPlaceholder"
                                   :selected="e.roleSetting && e.roleSetting.positions"
                                   @change="getSelectedPositions($event, e)">
                      </multiselect_permission>
                    </div>
                    <div class="">
                      <button class="btn-delete-permission blob is-action p-0" @click.stop="removeOffice(e.id)">
                        <img width="25px" src="@/assets/svgs/DocDetail/ic_delete.svg"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="modal-card-foot is-justify-content-center background__white">
              <button @click="submitSettingRole()"
                      class="button background__blue_main close-modal p-0 btn-save">
                {{ $t('buttons.submit') }}
              </button>
              <button @click="closeModal('modal-preview-file'); resetData()"
                      class="button close-modal btn-back background__white_pink">
                {{ $t('buttons.cancel') }}
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {DocumentService} from '@/services'
import {ACTION_SET_ACTIVE_SIDEBAR} from "@/stores/common/actions"
import {momentFormat} from "@/filters"
import i18n from "@/lang/i18n";
import User from '@/mixins/user'
import findIndex from 'lodash/findIndex'
// import {fileTypes} from "@/helpers/constant"
import pdf from 'vue-pdf';

export default {
  name: "BtoB",
  mixins: [User],
  components: {
    pdf
  },
  data() {
    return {
      src: '',
      paginate: {
        currentPage: null,
        lastPage: null,
        perPage: null,
        total: null
      },
      pageRanges: [10, 20, 50, 100],
      documentDetail: {
        files: {
          data: [],
        }
      },
      pdf: pdf,
      checkAll: false,
      hasChecked: false,
      fileView: {},
      fileVersions: [],
      fileVersion: {},
      filePreview: {},
      selectedOffices: [],
      // oldSelectedOffices: [],
      isPreview: false,
      isChangeRole: false,
      docPermission: [],
      submitAvailable: true,
      numPages: 0,
      displayTooltip: true,
      displayFile: false,
      displayAttributes: false,
      attributesClone: [],
    }
  },
  computed: {
    docId() {
      return this.$route.params.id
    },
    organization() {
      if (this.documentDetail.office && this.documentDetail.office.division && this.documentDetail.office.division.branch) {
        return this.documentDetail.office.division.branch.name + ' > ' + this.documentDetail.office.division.name + ' > ' + this.documentDetail.office.name
      }
      return ''
    }
  },
  watch: {
    offices(val) {
      if (val) {
        if (this.documentDetail.document_permission && this.documentDetail.document_permission.length) {
          this.documentDetail.document_permission.map((e) => {
            let index = findIndex(val, el => el.id === e.office_id)
            if (index >= 0) {
              let positions = []
              e.positions_id.split(',').map((el) => {
                positions = [...positions, ...[el * 1]]
              })
              let roleSetting = {
                office: e.office_id,
                positions
              }
              this.selectedOffices = [...this.selectedOffices, ...[{...val[index], roleSetting}]]
            }
          })
          localStorage.setItem('selectedOffices', JSON.stringify(this.selectedOffices))
          // this.oldSelectedOffices = [...this.selectedOffices]
        }
      }
    }
  },
  methods: {
    closeModal(id) {
      if (this.modalOpened) {
        this.$nextTick(() => {
          if (this.$el.querySelector(`#${id}`)) {
            this.$el.querySelector(`#${id}`).classList.remove('is-active')
          }
        })
        setTimeout(() => {
          this.modalOpened = false
          this.displayTooltip = true
        })
      }
    },
    loadPdf() {
      if (this.filePreview.type === 2) {
        this.src = this.pdf.createLoadingTask(this.filePreview.url);
        this.src.promise.then(pdf => {
          this.numPages = pdf.numPages;
          this.displayFile = true
        })
      }
    },
    resetData() {
      this.selectedOffices = JSON.parse(localStorage.getItem('selectedOffices')) || []
    },
    momentFormat(date, format = 'YYYY-MM-DD') {
      return momentFormat(date, format)
    },
    async getDocumentDetail(page, limit) {
      await DocumentService.detail(this.docId, page, limit)
          .then((res) => {
            if (res && res.data) {
              this.documentDetail = res.data
              this.checkAll = false
              this.hasChecked = false
              if (res.data.files) {
                this.paginate = {
                  currentPage: res.data.files.current_page,
                  lastPage: res.data.files.last_page,
                  perPage: res.data.files.per_page * 1,
                  total: res.data.files.total,
                }
              }
              if (this.documentDetail.document_permission && this.documentDetail.document_permission.length) {
                this.selectedOffices = []
                this.documentDetail.document_permission.map((e) => {
                  let index = findIndex(this.offices, el => el.id === e.office_id)
                  if (index >= 0) {
                    let positions = []
                    e.positions_id.split(',').map((el) => {
                      positions = [...positions, ...[el * 1]]
                    })
                    let roleSetting = {
                      office: e.office_id,
                      positions
                    }
                    this.selectedOffices = [...this.selectedOffices, ...[{...this.offices[index], roleSetting}]]
                  }
                })
                localStorage.setItem('selectedOffices', JSON.stringify(this.selectedOffices))
                // this.oldSelectedOffices = [...this.selectedOffices]
              }
              if (this.documentDetail.attributes) {
                this.documentDetail.attributes.map((e) => {
                  e.display = e.pivot.value
                  if (e.value && e.value.length) {
                    e.value.map((el) => {
                      if (el.id === e.pivot.value * 1) {
                        e.display = el.name
                      }
                    })
                  }
                  if (e.pivot.start_date || e.pivot.end_date) {
                    if (!e.pivot.start_date) {
                      e.display =' ~ ' + momentFormat(e.pivot.end_date)
                    } else if (!e.pivot.end_date) {
                      e.display = momentFormat(e.pivot.start_date) + ' ~ '
                    } else {
                      e.display = momentFormat(e.pivot.start_date) + ' ~ ' + momentFormat(e.pivot.end_date)
                    }
                  }
                })
                this.displayAttributes = false
                this.attributesClone = []
                this.documentDetail.attributes.map((e) => {
                  if (e.display) {
                    this.displayAttributes = true
                    this.attributesClone = [...this.attributesClone, ...[e]]
                  }
                })
              }
            }
          })
          .catch((err) => {
            console.log(err)
            // this.$toast.error('Can not get document detail')
          })
    },
    createDocument() {
      this.$router.push({
        name: 'CreateDocument',
        params: {id: this.documentDetail.folder ? this.documentDetail.folder.id : null}
      }, () => {
      })
    },
    updateDocument() {
      this.$router.push({
        name: 'UpdateDocument',
        params: {id: this.documentDetail.id}
      }, () => {
      })
    },
    deleteDocument() {
      this.$popup(
          this.$t('documents.popup.delete_document.title'),
          this.$t('documents.popup.delete_document.content', {name: this.documentDetail.name}),
          {
            okText: this.$t('documents.popup.delete_document.ok'),
            cancelText: this.$t('documents.popup.delete_document.cancel'),
            iconClass: 'info-circle',
            type: 'warning'
          },
          {
            onOk: async (d) => {
              await DocumentService.delete(this.documentDetail.id)
                  .then(() => {
                    this.$toast.success(this.$t('documents.messages.delete_document_success'))
                    this.$router.push({name: 'Folders'}, () => {
                    })
                  })
                  .catch((err) => {
                    console.log(err)
                    this.$toast.error(this.$t('documents.messages.delete_failed'))
                  })
              d.closeDialog()
              this.displayTooltip = true
            },
            onCancel: (d) => {
              d.closeDialog()
              this.displayTooltip = true
            },
            onClickOut: (d) => {
              d.closeDialog()
              this.displayTooltip = true
            }
          }
      )
    },
    settingAlertMail() {
      this.$router.push({name: 'SettingAlertMail', params: {id: this.documentDetail.id}}, () => {
      })
    },
    settingRole() {
      this.isPreview = false
      this.isChangeRole = true
      this.toggleModal('modal-preview-file')
      this.getDocumentDetail(this.paginate.currentPage, this.paginate.perPage)
      setTimeout(() => {
        this.modalOpened = true
      })
    },
    isSelected(id) {
      let flag = false
      if (this.selectedOffices) {
        this.selectedOffices.map((e) => {
          if (e.id === id) {
            flag = true
          }
        })
      }
      return flag
    },
    getSelectedOffices(e) {
      this.selectedOffices = [...this.selectedOffices]
      e.map((el) => {
        let index = findIndex(this.selectedOffices, a => a.id === el.id)
        if (index < 0) {
          el.roleSetting = {
            office: el.id,
            positions: [],
          }
          this.selectedOffices = [...this.selectedOffices, ...[el]]
        }
      })
      this.selectedOffices.map((el) => {
        let index = findIndex(e, a => a.id === el.id)
        if (index < 0) {
          this.removeOffice(el.id)
        }
      })
    },
    removeOffice(id) {
      let index = findIndex(this.selectedOffices, e => e.id === id)
      if (index >= 0) {
        this.selectedOffices.splice(index, 1)
      }
      this.selectedOffices.map((e, i) => {
        this.$refs[`multiSelect${i}`][0].updateSelected(e.roleSetting.positions)
      })
      if (!this.selectedOffices || !this.selectedOffices.length) {
        this.$refs['officesMultiselect'].updateSelected([])
      }
    },
    cancelSettingRole() {
      // this.selectedOffices = [...this.oldSelectedOffices]
    },
    submitSettingRole() {
      if (this.submitAvailable) {
        let data = []
        // this.oldSelectedOffices = [...this.selectedOffices]
        this.selectedOffices.map((e) => {
          data = [...data, ...[e.roleSetting]]
        })
        this.submitAvailable = false
        DocumentService.settingRole(this.docId, data)
            .then(() => {
              this.submitAvailable = true
              localStorage.setItem('selectedOffices', JSON.stringify(this.selectedOffices))
              this.$toast.success(i18n.t('documents.toast.success'))
              this.closeModal('modal-preview-file')
            })
            .catch(() => {
              this.submitAvailable = true
              this.$toast.error(i18n.t('documents.detail.setting_role_fails'))
              this.closeModal('modal-preview-file')
            })
      }
    },
    getSelectedPositions(event, e) {
      let index = findIndex(this.selectedOffices, el => el.id === e.id)
      if (index >= 0) {
        let positions = []
        event.map((el) => {
          positions = [...positions, ...[el.id]]
        })
        this.selectedOffices[index].roleSetting = {
          office: e.id,
          positions,
        }
      }
    },
    downloadURI(file, name, type) {
      let blob = new Blob([file], {type})
      const data = window.URL.createObjectURL(blob)
      let link = document.createElement('a')
      link.download = name
      link.href = data
      document.body.appendChild(link)
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 100)
    },
    async downloadFile(e) {
      await DocumentService.downloadFile(e.id)
          .then((res) => {
            this.downloadURI(res.data, e.original_name, res.headers['content-type'])
          })
          .catch((err) => {
            console.log(err)
            this.$toast.error(i18n.t('documents.detail.download_file_fails'))
          })
    },
    async downloadFileHistories(id, name) {
      await DocumentService.downloadFileHistory(id)
          .then((res) => {
            this.downloadURI(res.data, name, res.headers['content-type'])
          })
          .catch((err) => {
            console.log(err)
            this.$toast.error(i18n.t('documents.detail.download_file_fails'))
          })
    },
    viewFile(file) {
      this.isPreview = true
      this.isChangeRole = false
      this.displayTooltip = false
      this.displayFile = false
      this.getFileVersions(file.id, file.file_format)
      this.toggleModal('modal-preview-file')
      setTimeout(() => {
        this.modalOpened = true
        let modal_file = document.querySelector('.preview-file')
        modal_file.scrollLeft = 0
      })
    },
    selectFileVersion(e) {
      if (this.fileVersion.id !== e.id) {
        this.fileVersion = e
        this.fileView = {
          id: e.id,
          original_name: e.original_name,
        }
        this.closeDropdown('dropdown-office')
        this.closeDropdown('dropdown-file-versions')
        this.$set(this.filePreview, 'type', e.file_format)
        if (e.file_format * 1 !== 1) {
          this.getFilePreview(e.id)
        }
      }
    },
    async getFileVersions(id, format) {
      await DocumentService.fileVersions(id)
          .then((res) => {
            if (res) {
              this.displayFile = true
              this.fileVersions = res.data.file_histories
              this.fileVersions.sort((a, b) => (a.version < b.version) ? 1 : -1)
              this.fileVersion = this.fileVersions[0]
              this.fileView = {
                id: this.fileVersion.id,
                original_name: this.fileVersion.original_name,
              }
              this.$set(this.filePreview, 'type', this.fileVersion.file_format)
              if (format * 1 !== 1) {
                this.getFilePreview(this.fileVersion.id)
              }
            }
          })
          .catch((err) => {
            console.log(err)
            this.$toast.error(i18n.t('documents.detail.can_not_preview_file'))
          })
    },
    async getFilePreview(id) {
      await DocumentService.filePreview(id)
          .then((res) => {
            if (res) {
              this.$set(this.filePreview, 'url', res.data.url)
              this.loadPdf()
            }
          })
          .catch((err) => {
            console.log(err)
            this.$toast.error(i18n.t('documents.messages.validation.no_preview'))
          })
      },
      async deleteFile(id, originalName) {
        this.displayTooltip = false
        this.$popup(
          this.$t('documents.popup.delete_files.title'),
          this.$t('documents.popup.delete_files.content', { name: originalName }),
          {
            okText: this.$t('documents.popup.delete_files.ok'),
            cancelText: this.$t('documents.popup.delete_files.cancel'),
            iconClass: 'info-circle',
            type: 'warning'
          },
          {
            onOk: async (d) => {
              await DocumentService.deleteFile(id)
                .then(() => {
                  this.getDocumentDetail(this.paginate.currentPage, this.paginate.perPage)
                  this.$toast.success(this.$t('documents.messages.delete_file_success'))
                })
                .catch((err) => {
                  console.log(err)
                  this.$toast.error(this.$t('documents.messages.delete_failed'))
                })
              d.closeDialog()
              this.displayTooltip = true
            },
            onCancel: (d) => {
              d.closeDialog()
              this.displayTooltip = true
            },
            onClickOut: (d) => {
              d.closeDialog()
              this.displayTooltip = true
            }
          }
      )
    },
    toggleCheckAll() {
      this.hasChecked = false
      this.documentDetail.files.data.map((e) => {
        e.checked = this.checkAll
        if (e.checked) {
          this.hasChecked = true
        }
      })
    },
    checkItem() {
      let flag = true
      this.hasChecked = false
      this.documentDetail.files.data.map((e) => {
        if (!e.checked) {
          flag = false
        } else {
          this.hasChecked = true
        }
      })
      this.checkAll = flag
    },
    closeDropPageRange() {
      this.$el.querySelector('#dropdown-pagination').classList.remove('is-active')
    },
    toggleDropPageRange() {
      this.$el.querySelector('#dropdown-pagination').classList.toggle('is-active')
    },
    changePageRange(range) {
      this.paginate.perPage = range
      this.closeDropPageRange()
      this.getDocumentDetail(1, this.paginate.perPage)
    },
    changePage(page) {
      this.getDocumentDetail(page, this.paginate.perPage)
    },
  },
  created() {
    this.getData('offices')
    this.getData('positions')
    let prev = localStorage.getItem('prev')
    this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, prev || 'folders')
    this.paginate.perPage = 10
    this.getDocumentDetail(1, this.paginate.perPage)
    localStorage.removeItem('selectedOffices')
  }
}
</script>

<style lang="scss" scoped>
.title-doc {
  padding: 10px 60px !important;
  background-color: $blue_document;
  border-radius: 10px 10px 0 0;
  .column {
    padding: 0px;
    .columns {
      .column {
        padding: 0px;
      }
    }
  }
}
.btn-download-ver {
  width: 100%;
}
.input-detail {
  height: 40px;
  padding-left: 20px;
  font-weight: bold;
  border: none !important;
  background-color: $bg_input !important;
  &:hover {
    border: 1px solid transparent;
  }
}
.btn-download-file {
  width: 34px;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  background-color: #ffffff !important;
}
.btn-delete-permission {
  border: none !important;
  box-shadow: none !important;
}
.tag-box {
  background-color: $bg_input;
  border-radius: 5px;
  padding: 5px 20px 0px 20px !important;
  display: flex;
  flex-wrap: wrap;
  .tags {
    .tag {
      margin: 0px !important;
    }
  }
}
.dropdown-menu {
  max-width: 120px !important;
  right: 14px !important;
  left: unset !important;
  padding-top: 0px !important;
}
.btn-detail {
  height: 25px;
  font-size: 12px;
  border-radius: 13px !important;
  justify-content: unset;
  padding: 4px 10px 4px 4px;
  img {
    margin-right: 10px;
  }
}
.page-list-data {
  border-radius: 0 0 10px 10px;
}
.list-file {
  border-radius: 10px;
}
.btn-delete-doc {
  height: 25px;
  font-size: 12px;
  background-color: #DA354A;
  border-radius: 13px !important;
  justify-content: unset;
  padding: 4px 10px 4px 4px;
  img {
    margin-right: 10px;
  }
}
.button {
  &.btn-download {
    border-radius: 18px;
    background-color: $blue_button_add;
    height: 25px;
    width: 120px !important;
    border: none !important;
    box-shadow: none !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}
.button-action-detail {
  border: none !important;
  border-radius: 100% !important;
}
.table {
  thead {
    tr {
      th {
        vertical-align: middle;
      }
    }
  }

}

.current-folder {
  white-space: normal;
}
.modal-card-foot {
  padding-top: 40px !important;
  padding-bottom: 60px !important;
}
.blob {
  width: 25px;
  height: 25px;
  top: 1px;
  left: 1px;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;

  &:hover {
    cursor: pointer;
  }
}
input {
  font-weight: bold !important;
}
button {
  &.btn-save {
    border-radius: 18px !important;
    border: none !important;
    padding: 0px !important;
    height: 35px !important;
    width: 140px !important;
    font-size: 16px !important;
  }
  &.btn-back {
    border-radius: 18px !important;
    border: none !important;
    padding: 0px !important;
    height: 35px !important;
    width: 140px !important;
    font-size: 16px !important;
    color: $btn_back !important;
  }
  &.btn-change-page-range {
    height: 20px !important;
    width: 80px !important;
    border-radius: 10px !important;
    &:focus{
      box-shadow: none !important;
      border-color: $blue_main;
    }
  }
}
/*.document-name {*/
/*  font-weight: bold;*/
/*  word-break: break-all;*/
/*  white-space: normal;*/
/*}*/

.modal {
  .modal-card {
    max-width: 1024px;
    width: 100%;
    border-radius: 25px;

    .modal-card-body {
      min-height: 400px;
      overflow: hidden;

      .modal-field-content {
        padding: 0 20px;

        .dropdown-content {
          max-height: 200px;
          overflow-y: scroll;
        }
      }
    }

    .change-role-office {
      .modal-field-content {
        padding: 60px 130px;
        min-height: 470px;
        max-height: 550px;
        overflow-y: auto;
      }
    }
  }

  .content-dropdown-version::-webkit-scrollbar {
    display: none !important;
  }

  .action {
    width: 20%
  }

  .column-preview {
    padding: 10px;
    border: 1px solid $blue_main ;
    margin: 20px;
    max-height: 600px;
    min-height: 430px;
    overflow-y: scroll;
    position: relative;

    .preview-content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.button-action {
    .column {
      max-width: 180px !important;
    }
  }
.page-main-content {
  padding: 40px 60px 40px 60px;
  .columns {
    margin: 0px;
    .column {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
@media screen and (min-width: 769px) {
  .column-preview {
    max-height: 600px;
    min-height: 430px;
  }
  .level-right {
    display: flex;}
  }


.current_folder {
  white-space: normal;
}
@media screen and (max-width: 1366px) {
  .change-role-office {
    .modal-field-content {
      padding: 40px 50px !important;
    }
  }
  .btn-download-ver {
    width: 100%;
  }
}
.button-action {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  .column {
    padding: 10px !important;
  }
}

.document-tags {
  white-space: normal;
  word-break: break-all;
  height: auto;
  min-height: 2em;
}

@media screen and (min-device-width: 800px) and (max-device-width: 1280px) {
  .input-title {
    font-size: 15px;
  }
  .modal {
    .modal-card {
      max-width: 1024px;
      width: 100%;
      border-radius: 25px;

      .modal-card-body {
        min-height: 400px;
        overflow: hidden;

        .modal-field-content {
          padding: 0 20px;

          .dropdown-content {
            max-height: 200px;
            overflow-y: scroll;
          }
        }
      }
    }
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1500px) {
  .button-action {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    .column {
      padding: 10px !important;
    }
  }
}
@media only screen and (max-device-width: 1084px) {
  .title-doc {
    padding: 10px 20px !important;
  }
  .page-main-content {
    padding: 40px 20px;
  }
}
.page-main-content {
  .columns {
    .column {
      .field {
        padding-top: 20px !important;
      }
    }
  }
}

.break-spaces {
  white-space: pre-wrap;
}
</style>
